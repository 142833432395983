<template>
  <div class="xjgs">
    <van-nav-bar title="評估記錄" left-arrow @click-left="$router.back()" class="van-nav-bar-title" />
    <div class="content">
      <div class="disease-content">
        <div class="disease-title">
          近期疾病風險
        </div>
        <div class="disease-name">痛風</div>
        <div class="dis-chart">
          <dis-chart :ranges="ranges" :value="rate" :getLevel="getLevel"></dis-chart>
        </div>
        <div class="disease-content-title" v-if="level > 1">解讀</div>
        <div class="disease-content-text" v-if="level > 1">
          <div class="disease-content-text-value">
            AI算法分析出你的痛風風險值較高，意味著你身體在未來發生痛風疾病的可能性較大。尿酸鹽沉積在關節、軟組織或其他部位引起的炎症性疾病，它主要影響的是關節，但也可能對身體其他系統產生一系列不良影響。希望你近期抽出時間做一下相對全面的身體檢查，每年也要特別留意相關檢查，同時要注意調整作息，調整飲食增加運動。
          </div>
        </div>
      </div>
      <div class="disease-content">
        <img src="../../../assets/images/myLink/report/tf.png" class="disease-more" alt="more">
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import disChart from '../charts/disChart.vue'


export default {
  data () {
    return {
      disRR: [],
      disInfo: {
        dis: "fasd",
        name: "fasdads"
      },
      ranges: [0, 100, 200, 300],
      rate: 688,
      level: 0
    }
  },
  created () {
    const rate = Number(this.$route.params.rate)
    if (rate) {
      this.rate = rate;
    } else {
      this.$route.back()
    }
  },
  components: {
    "dis-chart": disChart
  },
  methods: {
    getLevel (level) {
      this.level = level;
    }
  }
}
</script>


<style lang="scss" scoped>
.xjgs {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;

  >div {
    width: 100%;
  }

  .van-nav-bar-title {
    flex: 0 0 auto;
  }

  .content {
    flex: 1;
    overflow: auto;
    padding: .24rem;

    >div {
      position: relative;
      width: 100%;
      margin-bottom: .24rem;

      &:last-child {
        margin-bottom: 0;
      }
    }


    .disease-content {
      border-radius: .16rem;
      background-color: #fff;

      >div {
        position: relative;
        width: 100%;
      }

      .disease-title {
        font-weight: 500;
        font-size: 0.36rem;
        color: #333333;
        text-align: left;
        padding: .32rem .28rem;
      }

      .disease-name {
        padding: .32rem .28rem;
        font-weight: 500;
        font-size: 0.36rem;
        color: #6681FA;
        line-height: 0.5rem;
        text-align: left;
        background: linear-gradient(90deg, rgba(104, 130, 251, 0.22) 0%, rgba(104, 130, 251, 0) 100%);
        display: flex;
        align-items: center;

        &::before {
          content: "";
          width: 0.46rem;
          height: 0.46rem;
          display: block;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          background-image: url(../../../assets/images/myLink/report/name-icon.png);
          margin-right: .12rem;
        }
      }

      .dis-chart {
        padding: .12rem;
      }

      .disease-content-title {
        display: flex;
        align-items: center;
        margin: .24rem 0;

        &::before {
          content: "";
          width: .12rem;
          height: .22rem;
          display: block;
          background: #6681FA;
          margin-right: .12rem;
        }
      }

      .disease-content-text {
        width: 100%;
        padding: .24rem;

        .disease-content-text-value {
          background: #F4F6FC;
          border-radius: 0.08rem;
          font-weight: 400;
          font-size: 0.28rem;
          color: #333333;
          line-height: 0.4rem;
          padding: 0.2rem;
          text-align: left;
        }
      }

      .disease-more {
        width: 100%;
      }
    }
  }
}
</style>
